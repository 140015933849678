import React, { createContext, useState } from "react";
import { connect, useSelector } from "react-redux";
import { BiHomeAlt } from "react-icons/bi";
import { BsCodeSlash } from "react-icons/bs";
// import { VscBellDot } from "react-icons/vsc";
import { GiChart, GiWallet } from "react-icons/gi";
import { IoCardOutline } from "react-icons/io5";
// import { FaQuestionCircle } from "react-icons/fa";

import {
  loginUser,
  logoutUser,
  updatePassword,
  updateUser,
  registerUser,
} from "./Actions/AuthActions";

import { clearErrors, restoreMsg, returnErrors } from "./Reducer/ErrorReducer";

import { getAutoBuy, manageAutoBuy } from "./Actions/DataActions";

import { getReload } from "./Actions/UserActions";

import { getUseCase, manageUpgrade } from "./Reducer/UseCaseReducer";
import { getSettings } from "./Reducer/SettingsReducer";

import { manageDocumentation } from "./Reducer/SocketReducer";

import {
  manageCGWalletHistory,
  manageCGWallets,
} from "./Reducer/CgWalletReducer";

import {
  getNotify,
  manageNotify,
  manageBanks,
  manageWalletPin,
  manageWithdrawal,
  manageFaqs,
} from "./Actions/NotificationAction";

import {
  buyServices,
  getServicesHistory,
  manageFundWallet,
  getManualBonusHistory,
  getWalletHistory,
  manageWallet,
  manageFundWalletFlutterwave,
  generateVirtual,
  getCards,
  getDataHistory,
  converterServices,
  manageFundWalletPaystack,
  getReferrals,
} from "./Actions/GeneralAction";
import {
  socketProfile,
  socketOnline,
  socketTransaction,
} from "./Reducer/SocketReducer";

import {
  getCategory,
  getProducts,
  getBiller,
  getGateway,
  getNetwork,
} from "./Actions/ProviderAction";
// import axios from "axios";

export const GlobalState = createContext();

const DataProvider = ({
  children,
  registerUser,
  clearErrors,
  restoreMsg,
  returnErrors,
  loginUser,
  logoutUser,
  updatePassword,
  updateUser,
  getCategory,
  getProducts,
  getReload,
  buyServices,
  socketProfile,
  socketOnline,
  converterServices,
  getUseCase,
  getSettings,
  getNotify,
  socketTransaction,
  getServicesHistory,
  manageFundWallet,
  getManualBonusHistory,
  getWalletHistory,
  manageWallet,
  manageFundWalletFlutterwave,
  generateVirtual,
  getCards,
  getDataHistory,
  manageFundWalletPaystack,
  getAutoBuy,
  manageAutoBuy,
  manageNotify,
  manageBanks,
  manageWalletPin,
  manageWithdrawal,
  getReferrals,
  manageDocumentation,
  manageCGWalletHistory,
  manageCGWallets,
  manageFaqs,
  manageUpgrade,
  getBiller,
  getGateway,
  getNetwork,
}) => {
  const {
    auth,
    errors,
    settings,
    notifications,
    success,
    category,
    products,
    data,
    usecase,
    upgrade,
    network,
    transactions,
    wallet,
    bonus,
    commission,
    airtimes,
    converter,
    socket,
    electricity,
    biz,
    cables,
    auto_buy,
    biller,
    education,
    withdrawal,
    referral,
    faqs,
    documentation,
    cgwallet,
    gateway,
  } = useSelector((state) => state);

  let [stateName, setStateName] = useState(""),
    [countryState, setCountryState] = useState([]);

  let getStates = async () => {
    let url = `https://nga-states-lga.onrender.com/fetch`;

    try {
      const response = await fetch(url, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const responseData = await response.json();
      // console.log({ responseData });
      setCountryState(
        responseData?.map((it) => {
          return { name: it };
        })
      );
    } catch (error) {
      console.log({ error }, "country", "fetch");
    }
  };
  // useEffect(() => {
  //   getStates();
  // }, []);

  let numberWithCommas = (x, a) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, a ? a : ",");
  };

  let nairaSign = <span className="fontInherit">&#8358;</span>;
  let nairaSignNeutral = "₦";

  let sidebarList = [
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: <BiHomeAlt className="icon" color="#1b1b1b" size={24} />,
    },
    {
      name: "Transactions",
      url: "/transactions",
      icon: <GiChart className="icon" color="#1b1b1b" size={24} />,
    },
    {
      name: "Products",
      url: "/products",
      icon: <IoCardOutline className="icon" color="#1b1b1b" size={24} />,
    },
    // {
    // 	name: "Notification",
    // 	url: "/notifications",
    // 	icon: <VscBellDot className="icon" color="#1b1b1b" size={24} />,
    // },
    {
      name: "Wallet",
      url: "/wallets",
      icon: <GiWallet className="icon" color="#1b1b1b" size={24} />,
    },
    // {
    // 	name: "Faqs",
    // 	url: "/faqs",
    // 	icon: <FaQuestionCircle className="icon" color="#1b1b1b" size={24} />,
    // },
    {
      name: "Api Documentation",
      url: "/documentation",
      icon: <BsCodeSlash className="icon" color="#1b1b1b" size={24} />,
    },
  ];

  const state = {
    numberWithCommas,
    sidebarList,
    stateName,
    setStateName,
    nairaSign,
    nairaSignNeutral,
    auth,
    loginUser,
    logoutUser,
    errors,
    clearErrors,
    settings,
    updateUser,
    notifications,
    success,
    restoreMsg,
    returnErrors,
    updatePassword,
    category,
    getCategory,
    products,
    getProducts,
    data,
    usecase,
    upgrade,
    network,
    transactions,
    wallet,
    getReload,
    buyServices,
    bonus,
    commission,
    airtimes,
    converter,
    socket,
    socketProfile,
    socketOnline,
    electricity,
    converterServices,
    biz,
    cables,
    getUseCase,
    getSettings,
    getNotify,
    manageNotify,
    socketTransaction,
    getServicesHistory,
    manageFundWallet,
    getManualBonusHistory,
    getWalletHistory,
    manageWallet,
    manageFundWalletFlutterwave,
    generateVirtual,
    getCards,
    getDataHistory,
    manageFundWalletPaystack,
    auto_buy,
    getAutoBuy,
    manageAutoBuy,
    biller,
    registerUser,
    education,
    withdrawal,
    manageBanks,
    manageWalletPin,
    manageWithdrawal,
    referral,
    getReferrals,
    faqs,
    documentation,
    manageDocumentation,
    manageCGWalletHistory,
    manageCGWallets,
    cgwallet,
    manageFaqs,
    gateway,
    manageUpgrade,
    countryState,
    getStates,
    getBiller,
    getGateway,
    getNetwork,
  };

  return <GlobalState.Provider value={state}>{children}</GlobalState.Provider>;
};

export default connect(null, {
  loginUser,
  clearErrors,
  logoutUser,
  updateUser,
  restoreMsg,
  returnErrors,
  updatePassword,
  getCategory,
  getProducts,
  getReload,
  buyServices,
  socketProfile,
  socketOnline,
  converterServices,
  getUseCase,
  getSettings,
  getNotify,
  socketTransaction,
  getServicesHistory,
  manageFundWallet,
  getManualBonusHistory,
  getWalletHistory,
  manageWallet,
  manageFundWalletFlutterwave,
  generateVirtual,
  getCards,
  getDataHistory,
  manageFundWalletPaystack,
  getAutoBuy,
  manageAutoBuy,
  registerUser,
  manageNotify,
  manageBanks,
  manageWalletPin,
  manageWithdrawal,
  getReferrals,
  manageDocumentation,
  manageCGWalletHistory,
  manageCGWallets,
  manageFaqs,
  manageUpgrade,
  getBiller,
  getGateway,
  getNetwork,
})(DataProvider);
