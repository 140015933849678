import jsPDF from "jspdf";
import "jspdf-autotable";
import robotoRegular from "./robotoRegular";

const exportTableToPDF = (tableId, fileName) => {
  const doc = new jsPDF({
    orientation: "landscape",
    unit: "pt",
    format: "a4",
  });

  doc.addFileToVFS("Roboto-Regular.ttf", robotoRegular);
  doc.addFont("Roboto-Regular.ttf", "Roboto", "normal");
  doc.setFont("Roboto");

  // Get the table element
  const table = document.getElementById(tableId);
  if (!table) return;

  // Clone the table to modify it for export
  const clonedTable = table.cloneNode(true);

  // Check and add the "S/N" column if missing
  const headerRow = clonedTable.querySelector("thead tr");
  const headerCells = Array.from(headerRow.children);
  let hasSerialNumber = headerCells.some((th) => th.innerText === "S/N");

  if (!hasSerialNumber) {
    // Add "S/N" as the first header
    const snHeader = document.createElement("th");
    snHeader.innerText = "S/N";
    headerRow.insertBefore(snHeader, headerRow.firstChild);

    // Add "S/N" data to each row in the tbody
    const bodyRows = clonedTable.querySelectorAll("tbody tr");
    bodyRows.forEach((row, index) => {
      const snCell = document.createElement("td");
      snCell.innerText = index + 1; // Add row index as the S/N value
      row.insertBefore(snCell, row.firstChild);
    });
  }

  // Remove "Action" column if present
  const actionIndex = headerCells.findIndex((th) => th.innerText === "Action");
  if (actionIndex !== -1) {
    // Remove the "Action" header
    headerRow.deleteCell(actionIndex + 1);

    // Remove the "Action" data from each row
    const bodyRows = clonedTable.querySelectorAll("tbody tr");
    bodyRows.forEach((row) => {
      row.deleteCell(actionIndex + 1);
    });
  }

  doc.autoTable({
    html: clonedTable,
    theme: "grid", // Add borders to cells
    styles: {
      fontSize: 10,
      cellPadding: 9,
      font: "Roboto",
    },
    headStyles: {
      fillColor: [22, 160, 133], // Custom header color
    },
    columnStyles: {
      0: { cellWidth: "auto" },
    },
    startY: 20,
  });

  doc.save(`${fileName}.pdf`);
};

export default exportTableToPDF;

export const exportDivTableToPDF = (headers, data, fileName) => {
  const doc = new jsPDF({
    orientation: "landscape",
    unit: "pt",
    format: "a4",
  });

  const hasSerialNumber = headers.some((header) => header.title === "S/N");
  let updatedHeaders = [...headers];
  if (!hasSerialNumber) {
    updatedHeaders = [{ title: "S/N", field: "sn" }, ...headers];
  }

  const bodyData = data.map((item, index) => {
    const row = updatedHeaders.map((header) => {
      if (header.field === "sn") return index + 1; // Add "S/N" value if needed
      return item[header.field];
    });
    return row;
  });

  doc.addFileToVFS("Roboto-Regular.ttf", robotoRegular);
  doc.addFont("Roboto-Regular.ttf", "Roboto", "normal");
  doc.setFont("Roboto");

  doc.autoTable({
    head: [updatedHeaders.map((header) => header.title)],
    body: bodyData,
    theme: "grid",
    styles: {
      fontSize: 10,
      cellPadding: 9,
      font: "Roboto",
    },
    headStyles: {
      fillColor: [22, 160, 133],
    },
    columnStyles: {
      0: { cellWidth: "auto" },
    },
  });

  doc.save(`${fileName}.pdf`);
};

export const exportDataListTable = (networkData) => {
  const doc = new jsPDF();
  let startY = 10; // For table positioning

  // Iterate through each network
  networkData.forEach((network) => {
    // Add h5 equivalent
    doc.setFontSize(14);
    doc.text(network.name, 14, startY);
    startY += 6;

    network.subCategories.forEach((category) => {
      // Add h6 equivalent
      doc.setFontSize(12);
      doc.text(category.name, 14, startY);
      startY += 6;
      doc.addFileToVFS("Roboto-Regular.ttf", robotoRegular);
      doc.addFont("Roboto-Regular.ttf", "Roboto", "normal");
      doc.setFont("Roboto");

      // Add the table data for each category
      doc.autoTable({
        startY: startY,
        head: [
          [
            "S/N",
            "Network",
            "Category",
            "Price",
            "Reseller",
            "Agent",
            "Validity",
            "Allowance",
          ],
        ],
        body: category.items.map((item, index) => [
          index + 1,
          item.network,
          item.category.name,
          item.price ? `₦ ${Number(item.price).toFixed(2)}` : "N/A",
          item.resellerPrice
            ? `₦ ${Number(item.resellerPrice).toFixed(2)}`
            : "0",
          item.agentPrice ? `₦ ${Number(item.agentPrice).toFixed(2)}` : "0",
          item.validity,
          `${item.allowance} ${item.size}`,
        ]),
        theme: "grid", // Table style
        styles: {
          font: "Roboto",
        },
      });

      // Update startY position after the table
      startY = doc.lastAutoTable.finalY + 10;
    });
  });

  // Save the PDF
  doc.save("data-list-export.pdf");
};

export const exportCableListDataToPDF = (cables) => {
  const doc = new jsPDF();

  // Cable List section
  doc.addFileToVFS("Roboto-Regular.ttf", robotoRegular);
  doc.addFont("Roboto-Regular.ttf", "Roboto", "normal");
  doc.setFont("Roboto");
  doc.setFontSize(12);
  doc.text("Cable List", 14, 15); // Section title
  doc.autoTable({
    startY: 20,
    head: [["S/N", "Name"]],
    body: cables?.cable_direct?.map((item, i) => [i + 1, item?.name]),
    styles: {
      font: "Roboto",
    },
  });

  // DStv List section
  const dstvY = doc.previousAutoTable.finalY + 10; // Get the Y position after the previous table
  doc.addFileToVFS("Roboto-Regular.ttf", robotoRegular);
  doc.addFont("Roboto-Regular.ttf", "Roboto", "normal");
  doc.setFont("Roboto");
  doc.text("DStv List", 14, dstvY);
  doc.autoTable({
    startY: dstvY + 5,
    head: [["S/N", "Name", "Price"]],
    body: cables?.cable_package?.dstv?.map((item, i) => [
      i + 1,
      item?.package || item?.name,
      item?.price || item?.plan_amount
        ? `₦ ${Number(item?.price || item?.plan_amount).toFixed(2)}`
        : "",
    ]),
    styles: {
      font: "Roboto",
    },
  });

  // GOtv List section
  const gotvY = doc.previousAutoTable.finalY + 10;
  doc.addFileToVFS("Roboto-Regular.ttf", robotoRegular);
  doc.addFont("Roboto-Regular.ttf", "Roboto", "normal");
  doc.setFont("Roboto");
  doc.text("GOtv List", 14, gotvY);
  doc.autoTable({
    startY: gotvY + 5,
    head: [["S/N", "Name", "Price"]],
    body: cables?.cable_package?.gotv?.map((item, i) => [
      i + 1,
      item?.package || item?.name,
      item?.price || item?.plan_amount
        ? `₦ ${Number(item?.price || item?.plan_amount).toFixed(2)}`
        : "",
    ]),
    styles: {
      font: "Roboto",
    },
  });

  // Startimes List section
  const startimesY = doc.previousAutoTable.finalY + 10;
  doc.addFileToVFS("Roboto-Regular.ttf", robotoRegular);
  doc.addFont("Roboto-Regular.ttf", "Roboto", "normal");
  doc.setFont("Roboto");
  doc.text("Startimes List", 14, startimesY);
  doc.autoTable({
    startY: startimesY + 5,
    head: [["S/N", "Name", "Price"]],
    body: cables?.cable_package?.startimes?.map((item, i) => [
      i + 1,
      item?.package || item?.name,
      item?.price || item?.plan_amount
        ? `₦ ${Number(item?.price || item?.plan_amount).toFixed(2)}`
        : "",
    ]),
    styles: {
      font: "Roboto",
    },
  });

  // Save the PDF
  doc.save("cable_list_data.pdf");
};
