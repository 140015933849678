import React, { useContext, useEffect } from "react";
import { GlobalState } from "../Data/Context";
import { Container } from "reactstrap";

export const MainContent = ({ data, title }) => {
  return (
    <Container className="py-5">
      <section className="py-5">
        <MiddleHeader text={title || "Privacy Policy"} />
        {/* {data?.map((item, index) => (
					<li className="my-3 mb-5 list-group-item" key={index}>
						<h6 className="fw-600 text-uppercase tw-text-3xl Lexend">
							{item?.summary}
						</h6>
						<ul>
							<pre className="my-1 list-group-item tw-text-xl tw-leading-loose tw-text-justify tw-font-semibold tw-font-sans">
								{item?.details}
							</pre>
						</ul>
					</li>
				))} */}
        <div className="my-3 mb-5">
          <h6 className="fw-600 text-uppercase tw-text-3xl Lexend">
            introduction
          </h6>
          <p className="tw-text-justify tw-font-medium tw-font-sans tw-text-xl tw-my-1 tw-leading-loose list-group-item">
            At Honour World Limited (Honour World), we appreciate the importance
            of privacy and as such, we hold it in high esteem. This privacy
            notice is created to ensure that users understand how their personal
            information is collected and utilized in the course of using the
            Mobile Application/ Website (‘‘Honour World Mobile App/ Web’’), its
            features, and services as described in this Privacy Policy. Kindly
            read through the Privacy Policy and click on the ‘AGREE’ icon made
            available below accordingly. If there are any terms in this privacy
            policy that you do not agree with, kindly discontinue the use of our
            Services immediately. By clicking the ‘AGREE’ icon, it shows you
            have accepted our Privacy Policy and Terms of Service.
            <br />
            <br />
            <span className="tw-font-semibold tw-text-2xl">Find Out More:</span>
            <br />
            Honour World App (App means “Application”) is an internet platform
            where we (We, Us and Our, means “Honour World”,) offer Seamless
            transactions which includes Sales of Data plans to our Users at
            Affordable rate, Sales of Airtime, Conversion of Airtime to Cash,
            Cable TV Subscriptions, Electricity Subscriptions, Wallet to Wallet
            Transfer, Timely Settings of any of our services for Automatic
            Purchase/Renewal (Auto Buy) of any of your transactions, Business
            Verification, WAEC, NECO and JAMB Result Check Pins, CG Wallet
            (Corporate Gifting Wallet is a place where you keep your CG Stock
            after buying in bulk from Us and then reselling in the unit to your
            customers. Here, All your transactions will be charged from your
            wallet balance except for Corporate Gifting plans only), We also
            allow Profile Picture Upload for your Personal Use and Contact
            Selection for Easy Access and Performance. Clicking on the Agree
            button means you consent to the use of all these services.
            <br />
            <br />
            <span className="tw-font-semibold tw-text-2xl">
              The Information We Collect:
            </span>
            <br />
            The information we collect and store in our database is information
            you deliberately grant us when you register on the App. This means
            that you are not obliged to give any information that is outside
            your will or interest. Hence, downloading and engaging in activities
            on the App and obtaining information about us or our products or
            services is a clear expression of your interest and consent. The
            information that we collect depends on the context of your
            interactions with us and the App, the choices you make and the
            products and features you use. The personal and payment information
            we collect may include such as.
            <br />
            Personal Information: Names, phone numbers; user names; passwords;
            debit/credit card numbers; email addresses; and other similar
            information. However we do not store your debit/credit card numbers,
            nor do we store the contact list you choose from during quick phone
            book selection when making Airtime and Data purchases for instant
            purchases or Auto Buy. <br />
            Payment Information: We may collect data necessary to process your
            payment if you make purchases, such as your payment instrument
            number (such as a credit card number), and the security code
            associated with your payment instrument. All payment data is stored
            by Flutter-wave. You may find their privacy policy link here
            https://flutterwave.com/us/privacy-policy
            <br />
            Contact List: We may request to access your contact list, this is to
            help quick selection of contacts to recharge via Bill-point (Airtime
            & Data Purchases) and you equally have the permission to decline
            such request. <br />
            Push Notifications: We may request to send you push notifications
            regarding your account or certain features of the App. If you wish
            to opt out from receiving these types of communications, you may
            turn them off in your device's settings.
            <br />
            This information is primarily needed to maintain the security and
            operation of our App, for troubleshooting and for our internal
            analytic and reporting purposes.
            <br />
            Wallet-to-Wallet Transaction: When performing a Wallet-to-Wallet
            transaction on the App, we may request the Wallet ID of the
            Recipient of the value to facilitate the process.
            <br />
            <br />
            <span className="tw-font-semibold tw-text-2xl">
              How We Use The Information We Collect:
            </span>
            <br />
            The personal information collected or legitimately received via our
            App is processed for various legitimate business purposes, in
            accordance with the fulfilment of our contract with you via your
            consent and in compliance with our legal duties.
            <br />
            We indicate the specific processing grounds we rely on next to each
            purpose listed below.
            <br />
            To Facilitate Account Creation, Login Process, and Service Delivery:
            We use the information you provide us to help create an account for
            you to be able to make use of our services. If you choose to link
            your account with us to a third-party account (such as your Google
            or Facebook account), we use the information you allow us to collect
            from those third parties to facilitate account creation and login
            process for the performance of the contract. We also use your
            information to provide you with your requested services.
            <br />
            To Request Feedback from You via Cold-calling/Email: We may use your
            information (phone numbers and email addresses only) to request
            feedback and to contact you about your use of our App.
            <br />
            To Push Notifications: We may use your information (phone numbers
            and email addresses only) to send you push notifications regarding a
            certain feature or service of the App.
            <br />
            To Manage User Accounts: We may use your information for the
            purposes of managing our account and keeping it in working order.
            <br />
            To Fulfil and Manage Your Order: We may use your information to
            fulfil and manage your orders, payments, and exchanges(airtime to
            cash conversion, wallet-to-wallet transfer) made through the App.
            <br />
            To Administer Prize Draws and Competitions: We may use your
            information to administer prize draws and competitions when you
            elect to participate in our competitions and when you emerge as a
            winner in such competitions.
            <br />
            To Offer Support/Render Help to Users Enquiries: We may use your
            information to respond to your inquiries and solve any potential
            issues you might have with the use of our Services.
            <br />
            To Deliver Marketing and Promotional Communications: We and/or our
            third-party marketing partners may use the personal information you
            send to us for our marketing purposes if this is in accordance with
            your marketing preferences. For example, when expressing an interest
            in obtaining information about us or our App, subscribing to
            marketing or otherwise contacting us, we will collect personal
            information from you. You can opt out of our marketing emails at any
            time.
            <br />
            To Deliver Target Advertising: We may use your information to
            develop and display personalized content and advertising (and work
            with third parties who do so) tailored to your interests and/or
            location and to measure its effectiveness.
            <br />
            <br />
            <span className="tw-font-semibold tw-text-2xl">
              Duration of Information Storage:
            </span>
            <br />
            We keep your personal information for as long as it is necessary for
            the purposes set out in this privacy policy listed above unless a
            longer retention period is required or permitted by law (such as
            tax, accounting or other legal requirements). This is only within
            the period of time in which users have an account with us (That is,
            when you discontinue your account with us by your consent, we will
            either delete or anonymize such information or, if this is not
            possible (for example, because your personal information has been
            stored in backup archives), then we will securely store your
            personal information and isolate it from any further processing
            until deletion is possible.
            <br />
            Transaction history is not stored for up to 3 months in our
            database. That is, all transactions can only be accessed between
            1-2month alone so as to keep the functionality of the system up.
            This means that after a month, all transactions are automatically
            cleared from our system.
            <br />
            <br />
            <span className="tw-text-2xl tw-font-semibold">
              Safeguarding of Mobile App Account:
            </span>
            <br />
            Your password is pivotal to your Mobile App and Website account. We
            encourage you to use unique numbers, letters, and special characters
            as your password, to secure your account. Users are advised to keep
            their passwords safe and not disclose them to any third party. If
            your password gets compromised, you are advised to immediately
            notify us of such an occurrence, and change your password
            accordingly. Clicking on the Agree button means you consent to how
            we safeguard your Mobile App Account as stated.
            <br />
            <br />
            <span className="tw-text-2xl tw-font-semibold">
              Securing/Protecting Your Information:
            </span>
            <br />
            As a firm, Personal Information like email address, name,
            residential address, phone number, etc required from the user for
            signing up is saved on your device and our database except for your
            ATM card details, which will be handled with utmost security. If we
            happen to obtain any personal information through support requests
            or other correspondence, it will be treated with stringent
            confidentiality. Any such personal information will never be shared
            with any other entities. Any such personal information will be
            retained towards the resolution of the support requests and for
            future reference if you need support again. We can delete the emails
            at any time also at your request. Please be informed that Honour
            World will not request your account password to resolve any
            complaint(s). Hence, for no reason should you reveal your password
            to any of our support personnel or share it while sending mail to us
            to log complaints, all you need to get a solution is your email
            address, sometimes your proof of payment/screen-shot of the receipt
            from the website or Mobile App, and your Wallet ID for refunding
            your wallet. Also, for you to become our Agent, you must do the KYC
            verification. KYC means \"Know Your Customer\". Clicking on the
            Agree button means that you consent to the Data Protection Policy.
            <br />
            <br />
            <span className="tw-text-2xl tw-font-semibold">
              Auto Buy Privacy:
            </span>
            <br />
            The Auto Buy on Our Platform allows you to automate your Electricity
            Bill Payments, Cable TV Payments, Airtime and Data Payments for a
            Renewal. You only need to set your purchase to your desired time and
            allow Us to take care of the rest with zero stress. The Data plans,
            Airtime plans you choose to buy, Phone Number of purchase, Cable TV
            Number and Meter Number you choose to recharge are strictly at the
            user's discretion, so do not act under pressure to do this for
            anyone. Hence, Clicking on the Agree button means that you consent
            to the use of this service and the privacy of this service.
            <br />
            <br />
            <span className="tw-text-2xl tw-font-semibold">
              Contact Selection Privacy:
            </span>
            <br />
            Honour World App allows you to select contact from your phone book
            contact list for Easy and quick processing of your Airtime and Data
            transactions. Honour World has no access to any such phone book
            contact information and will never request that you share any of
            your contact lists with us except by your selection for Data Plan
            Purchase, Airtime Plan Purchase, and Auto Buy Selection.
            Furthermore, Honour World Limited does not control the privacy
            policies of third parties, and you are subject to the privacy
            policies of those third parties where applicable. Hence, Clicking on
            the Agree button means that you consent to the use and the privacy
            of this service.
            <br />
            <br />
            <span className="tw-font-semibold tw-text-2xl">
              Profile Picture Privacy:
            </span>
            <br />
            Honour World does not mandate and has no access to your profile
            picture. Hence, you can desire to upload it of your own volition or
            leave it blank. Furthermore, Honour World Limited does not control
            the privacy policies of third parties, and you are subject to the
            privacy policies of those third parties where applicable. Hence, it
            is advised that you keep your App safe from third-party access.
            Clicking on the Agree button means that you consent to the use and
            privacy of this service.
            <br />
            <br />
            <span className="tw-text-2xl tw-font-semibold">
              CG Wallet Privacy:
            </span>
            <br />
            CG Wallet (Corporate Gifting) Wallet is a place where you keep your
            CG Stock after buying in bulk from Us and then reselling in units to
            your customers. Here, All your transactions will be charged from
            your wallet balance except for Corporate Gifting plans only). Honour
            World will never share your CG Wallet information with any party and
            has no control over the wallet usage. Furthermore, Honour World
            Limited does not control the privacy policies of third parties, and
            you are subject to the privacy policies of those third parties where
            applicable. Hence, it is advised that you keep your App safe from
            third-party access. Clicking on the Agree button means that you
            consent to the use and privacy of this service.
            <br />
            <br />
            <span className="tw-text-2xl tw-font-semibold">
              Wrong Number Input:
            </span>
            <br />
            Honour World Limited cannot be held responsible for any number
            entered incorrectly. Kindly double-check every Cell phone number,
            Smart Card number, Meter number, and so on that you are sending
            data, airtime, or any subscription to before clicking on the
            \"Payment\" button. Hence, we won't be held responsible if a user
            wants to subscribe to DSTV and input a wrong IUC number or a wrong
            meter number for electricity bill payment.
            <br />
            <br />
            <span className="tw-font-semibold tw-text-2xl">
              Complaints and Dispute Resolution:
            </span>
            <br />
            Honour World can only help to rectify complaints whose transactions
            are within the space of a month. Therefore, any transaction
            complaint that is beyond one month will not be attended to. The
            reason is that we keep records of transactions within one month only
            to avoid congestion. Hence, transaction histories are being flushed
            on our database every month to avoid server down and the slow
            operation of the APP and website. Airtime to Cash Agreement: The
            airtime conversion rate is not fixed, it fluctuates, and for this
            reason, our rate for each network varies per day. Hence, you will
            need to contact us via our social media platforms to know our buying
            rate any time you want to sell airtime to us. Honour World Limited
            reserves the right to return airtime if discovered to be fraudulent
            or gotten through illegal means at any point in time. Hence, do not
            bring fraudulent, hacked, or stolen airtime to sell to us. We buy
            airtime of all networks starting from ₦500.00 and above as well as
            VTU with receipt only. Payment is made only after confirmation of
            airtime and it is a must for you to send us the proof of sending.
            Moreover, you need not rush us for payment as you will surely get
            paid in return on or before the close of business on the day the
            airtime is sent.
            <br />
            <br />
            <span className="tw-text-2xl tw-font-semibold">
              Marketing and Promotion:
            </span>
            <br />
            Honour World Limited may use your personal information to share
            information with you on new products and services, promotional
            offers, and creative content that may be of interest to you. By
            opening our Mobile App account, you consent to us sending you
            updates about Honour World products.
            <br />
            <br />
            <span className="tw-font-semibold tw-text-2xl">Third Parties:</span>
            <br />
            Honour World cannot request Personal Information from you except on
            support requests or correspondence, so this document addresses only
            the disclosure and use of the information we collect from you. If
            you disclose your information to others, whether they are buyers or
            sellers on other sites throughout the internet, different rules may
            apply to their use or disclosure of the information you disclose to
            them. Hence, Honour World Limited does not control the privacy
            policies of third parties, and you are subject to the privacy
            policies of those third parties where applicable. We encourage you
            to ask questions before you disclose your personal information to
            others.
            <br />
            <br />
            <span className="tw-text-2xl tw-font-semibold">
              Error In Funding/Opting Out:
            </span>
            <br />
            In case of an over-funding error, you need to use up your wallet
            balance for transaction purposes only on the Mobile App or website.
            Honour World Limited doesn't transfer funds to banks. However, if
            you no longer want to use the Honour World Limited Mobile App or
            website, then you need to either use up your wallet balance for
            purchases or forfeit it. The reason is that Honour World Limited
            doesn’t allow the transfer of funds to the bank, for now, it might
            be considered in the near future.
            <br />
            <br />
            <span className="tw-font-semibold tw-text-2xl">
              Intellectual Property Rights:
            </span>
            <br />
            Intellectual Property Rights give an individual exclusive property
            rights over the use of these creations, thus granting them ownership
            rights over these intangible assets and preventing other people from
            using or reproducing them without the creator/owner’s prior consent.
            <br />
            This right is administered by several laws, including the Nigerian
            Copyright Act, the Trademarks Act and the Patent and Design Act.
            These Acts are saddled to protect/safeguard creators' industrial
            designs and images, artistic works, symbol names, copyright and
            trademarks, and other producers of intellectual goods and services,
            by granting them certain time-limited rights to control the use of
            their products, which enable people to earn recognition or derive
            financial benefit from what they invent or create. With this, you
            hereby agree that you consent to the use and adherence to the
            Intellectual Property Rights of this service and hence become a
            theft in case of any breach. <br />
            <br />
            <span className="tw-text-2xl tw-font-semibold">No to Piracy:</span>
            <br />
            Honour World Limited reserves the right to sue or prosecute
            anyone/organization who tries to clone our Mobile App or website.
            Hence, Perpetrators will be rightly punished for trying to
            fake/duplicate copies in our name to scam people. Also, Honour World
            doesn’t tolerate illegal or malicious activity being carried out on
            the Mobile App or website.
            <br />
            <br />
            <span className="tw-text-2xl tw-font-semibold">
              Reviewing of Privacy Policy:
            </span>
            <br />
            Honour World Limited reserves the right to modify our Privacy Policy
            at any time simply by posting such modification on this site and
            without any other notification. Any such modification will be
            effective immediately upon posting on this site. You are advised to
            refer to the Privacy Policy from time to time so that you are always
            in sync with our current privacy practices.
            <br />
            <br />
            <span className="tw-text-2xl tw-font-semibold">
              Honour World API Documentation:
            </span>
            <br />
            Honour World API grants access to a flexible and robust data
            vending, airtime vending, cable TV, and utility vending system that
            allows customers like you to integrate with our services connected
            to your platform for seamless vending as well. Honour World reserves
            the right to enable/disable any API user at any given time. The
            reason is if we run out of stock or we suspect any malicious
            activities going on.
            <br />
            <br />
            <span className="tw-text-2xl tw-font-semibold">Declaration:</span>
            <br />
            By choosing to proceed, you agree that you understand that you are
            opening an Honour World Limited Mobile App or website account and
            that all the information you have supplied is for this. You agree to
            abide by the above-listed terms of service, which govern our
            operations. By opening our Mobile App or Website Account, you
            consent to our carrying out regular fraud prevention checks.
          </p>
        </div>
      </section>
    </Container>
  );
};

const Privacy = () => {
  const { faqs, manageFaqs } = useContext(GlobalState);
  useEffect(() => {
    manageFaqs("privacy");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <MainContent data={faqs?.privacy} title={"Privacy Policy"} />;
};

export default Privacy;

export const MiddleHeader = ({ text, css, css2 }) => {
  return (
    <div
      className={`d-flex align-items-center mb-3 ${
        css2 ? css2 : "justify-content-center"
      }`}
    >
      <h1
        className={`text-capitalize textColor2 text-center textDefault Lexend lg:tw-text-6xl ${
          css ? css : ""
        }`}
      >
        {text}
      </h1>
    </div>
  );
};
