export const NetworkLoader = () => {
  return (
    <div className="tw-flex tw-flex-col tw-gap-1 tw-pt-[-20px]">
      <div className="loading-bar-container">
        <div className="loading-bar"></div>
      </div>
      <span className="tw-pb-2">Detecting Network...</span>
    </div>
  );
};
