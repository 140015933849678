import React, { useContext, useEffect, useState } from "react";
import { GlobalState } from "../Data/Context";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Container } from "reactstrap";
import { Buttons } from "../Utils";
import { toast } from "react-toastify";
// import { LogoHeader } from "../Components/Header";
// import { NumericFormat } from "react-number-format";
import Bg from "../Assets/registerbg.png";
import Phone from "../Assets/phone.svg";
import Lock from "../Assets/lock.svg";
import NameIcon from "../Assets/name.svg";
import TextInput from "../Components/Inputs/textinput";
import PasswordInput from "../Components/Inputs/passwordinput";

const Register = () => {
  const { registerUser, auth } = useContext(GlobalState);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let // [typePass, setTypePass] = useState(false),
    // [typePass2, setTypePass2] = useState(false),
    init = {
      firstName: "",
      lastName: "",
      telephone: "",
      email: "",
      password: "",
      confirmPassword: "",
      referral: "",
    },
    [state, setState] = useState(init),
    [loading, setLoading] = useState(false),
    [terms, setTerms] = useState(false),
    [submit, setSubmit] = useState(false),
    navigate = useNavigate(),
    textChange =
      (name) =>
      ({ target: { value } }) => {
        setState({ ...state, [name]: value });
      },
    [getSearch] = useSearchParams();

  useEffect(() => {
    if (getSearch?.get("referral"))
      setState({ ...state, referral: getSearch?.get("referral") });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSearch]);

  let handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !state?.password ||
      !state?.email ||
      !state?.firstName ||
      !state?.lastName ||
      !state?.telephone
    )
      return toast.info("Please fill out all fields");
    if (state?.password !== state?.confirmPassword)
      return toast.error("Password do not match");

    if (
      !/\d/.test(state?.password) ||
      // eslint-disable-next-line no-useless-escape
      !/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(state?.password) ||
      state?.password?.length < 8
    )
      return toast.error(
        `Password must be at least 8 characters long, contains at least 1 number, 1 uppercase, 1 lowercase letter and 1 special character`
      );

    let mailchecker = [
      "@gmail.com",
      "@yahoo.com",
      "@ymail.com",
      "@outlook.com",
      "@hotmail.com",
    ];

    if (!mailchecker.some((s) => state?.email?.trim().endsWith(s)))
      return toast.error("Invalid email");

    setLoading(true);
    await registerUser(state);
    setLoading(false);
    setSubmit(true);
  };

  useEffect(() => {
    if (submit && auth?.isRegistered) {
      navigate(auth?.regCase === "enable" ? "/activate" : "/login");
      setSubmit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit, auth?.isRegistered, auth?.regCase]);

  return (
    <div
      style={{
        background: `url(${Bg})`,
      }}
      className="tw-py-10 lg:tw-h-full tw-h-screen tw-w-full tw-flex tw-justify-center tw-items-center"
    >
      <div
        style={{
          borderRadius: "14.724px",
          border: "0.866px solid #DADADA",
          background: "rgba(255, 255, 255, 0.30)",
          backdropFilter: "blur(12.99212646484375px)",
        }}
        className="lg:tw-w-1/2 tw-py-14 tw-px-16"
      >
        <div onClick={() => navigate("/")} className="">
          <img
            src={process.env.REACT_APP_IMAGE_URL}
            alt={`logo ${process.env.REACT_APP_NAME}`}
            className="tw-h-16"
          />
        </div>
        <h3 className="tw-text-capitalize tw-py-5 tw-font-semibold general tw-text-black tw-text-3xl tw-text-left">
          Sign up
        </h3>
        <p className="general tw-text-base tw-pb-5 tw-font-medium tw-text-black">
          Please provide the following details for your new account
        </p>
        <form action="" onSubmit={handleSubmit} className="tw-mt-4 ">
          <div className="tw-space-y-2">
            <TextInput
              type={"text"}
              name={"firstName"}
              Icon={NameIcon}
              img={true}
              value={state.firstName}
              placeholder={"First Name"}
              onChange={textChange("firstName")}
              required={true}
            />
            <TextInput
              type={"text"}
              name={"lastName"}
              Icon={NameIcon}
              img={true}
              value={state.lastName}
              placeholder={"Last Name"}
              onChange={textChange("lastName")}
              required={true}
            />
            <TextInput
              type={"email"}
              name={"email"}
              img={false}
              value={state.email}
              placeholder={"Email"}
              onChange={textChange("email")}
              required={true}
            />
            <TextInput
              type={"tel"}
              name={"telephone"}
              Icon={Phone}
              maxLength={11}
              img={true}
              value={state.telephone}
              placeholder={"Phone Number"}
              onChange={textChange("telephone")}
              required={true}
            />
            <PasswordInput
              name={"password"}
              placeholder={"Password"}
              value={state.password}
              Icon={Lock}
              img={true}
              onChange={textChange("password")}
            />
            <PasswordInput
              name={"confirmPassword"}
              placeholder={"Confirm Password"}
              value={state.confirmPassword}
              Icon={Lock}
              img={true}
              onChange={textChange("confirmPassword")}
            />
            <TextInput
              type={"tel"}
              name={"referral"}
              Icon={NameIcon}
              img={true}
              value={state.referral}
              maxLength={8}
              placeholder={"Referral Code (Optional)"}
              onChange={textChange("referral")}
              readOnly={getSearch?.get("referral")}
            />
          </div>
          <div className="tw-mt-6">
            <Buttons
              onClick={handleSubmit}
              loading={loading}
              title={"sign up"}
              css="btn-primary1 text-capitalize py-3 w-100 my-2"
            />
            <div className="tw-flex tw-gap-4">
              <input
                type="checkbox"
                value={terms}
                onChange={(e) => setTerms(e.target.checked)}
                className="tw-rounded tw-border tw-border-[#2F75FD] tw-bg-[#CDE1FF]"
              />
              <span className="tw-text-lg tw-text-opacity-80 tw-text-black general tw-font-normal">
                By creating your account you agree to our{" "}
                <span className="tw-underline tw-text-lg tw-text-opacity-80 tw-text-black general tw-font-normal">
                  Terms <br /> and Conditions and Policies
                </span>
              </span>
            </div>
            <h6 className="tw-text-black tw-pt-6 tw-text-base tw-font-medium general">
              Already have an account? -{" "}
              <span
                onClick={() => navigate("/login")}
                className="tw-text-black tw-cursor-pointer tw-text-base tw-font-semibold general"
              >
                {" "}
                Sign in
              </span>
            </h6>
            <h6 className="tw-text-black tw-pt-3 tw-text-base tw-font-medium general">
              <span
                onClick={() => navigate("/activate")}
                className="tw-text-black tw-cursor-pointer tw-text-base tw-font-semibold general"
              >
                {" "}
                Activate account here{" "}
              </span>
            </h6>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;

export const DefaultAuthComponent = ({ children, nozoom, nobg }) => {
  return (
    <div
      className={`aboutScreen d-flex justify-content-center align-items-center py-md-5" ${
        nobg ? "" : "bg-select-blue"
      }`}
    >
      <div
        data-aos={nozoom ? "" : "zoom-in"}
        className="m-auto shadow px-3 py-5 rounded-20 shadow2 w-100 bg-white"
        style={{
          maxWidth: "500px",
        }}
      >
        <Container className="px-lg-5 px-3">{children}</Container>
      </div>
    </div>
  );
};

// const Junks = () => {
//   return (
//     <DefaultAuthComponent nozoom>
//     <>
//       <div
//         onClick={() => navigate("/")}
//         className="tw-flex tw-justify-center tw-my-4"
//       >
//         <img
//           src={process.env.REACT_APP_IMAGE_URL}
//           alt={`logo ${process.env.REACT_APP_NAME}`}
//           className="tw-h-16"
//         />
//       </div>
//       <h3 className="tw-text-capitalize tw-font-extrabold tw-text-6xl tw-text-[#1b1b1b] segoe tw-text-center">
//         Create account
//       </h3>
//       <small className="mb-2 tw-text-xl tw-font-medium d-block text-center">
//         Enjoy the things that you love!
//       </small>
//       <form className="mt-2" onSubmit={handleSubmit}>
//         <div className="mb-1">
//           <label htmlFor="firstName">First Name</label>
//           <input
//             type="text"
//             required
//             name="firstName"
//             className="form-control py-3"
//             value={state.firstName}
//             onChange={textChange("firstName")}
//           />
//         </div>
//         <div className="mb-3">
//           <label htmlFor="lastName">Last Name</label>
//           <input
//             type="text"
//             required
//             name="lastName"
//             className="form-control py-3"
//             value={state.lastName}
//             onChange={textChange("lastName")}
//           />
//         </div>
//         <div className="mb-3">
//           <label htmlFor="email">Email</label>
//           <input
//             type="email"
//             required
//             name="email"
//             className="form-control py-3"
//             value={state.email}
//             onChange={textChange("email")}
//           />
//         </div>
//         <div className="mb-3">
//           <label htmlFor="telephone">Phone number</label>
//           {/* <input
//             type="tel"
//             required
//             name="telephone"
//             className="form-control py-3"
//             value={state.telephone}
//             onChange={textChange("telephone")}
//             maxLength={11}
//           /> */}
//           <NumericFormat
//             className="form-control py-3"
//             value={state.phone}
//             allowLeadingZeros={true}
//             allowNegative={false}
//             displayType="input"
//             onValueChange={(val) =>
//               setState({
//                 ...state,
//                 telephone: val?.value.replace(/[^0-9]*/g, ""),
//               })
//             }
//             maxLength={11}
//             inputMode="tel"
//             renderText={(value, props) => <span {...props}>{value}</span>}
//             pattern="[0-9]"
//             decimalScale={0}
//           />
//         </div>
//         <div className="mb-3 show-hide2 show-hide position-relative">
//           <label htmlFor="Password">Password</label>
//           <input
//             type={typePass ? "text" : "password"}
//             required
//             name="password"
//             className="form-control py-3"
//             value={state.password}
//             onChange={textChange("password")}
//           />
//           <EyeToggle typePass={typePass} setTypePass={setTypePass} />
//         </div>
//         <div className="mb-5 show-hide2 show-hide position-relative">
//           <label htmlFor="ConfirmPassword">Confirm Password</label>
//           <input
//             type={typePass2 ? "text" : "password"}
//             required
//             name="confirmPassword"
//             className="form-control py-3"
//             value={state.confirmPassword}
//             onChange={textChange("confirmPassword")}
//           />
//           <EyeToggle typePass={typePass2} setTypePass={setTypePass2} />
//         </div>
//         <div className="mb-3">
//           <label htmlFor="referral">
//             Referral Code <small className="text-muted">(optional)</small>
//           </label>
//           <input
//             type="tel"
//             required
//             name="referral"
//             className="form-control py-3"
//             value={state.referral}
//             maxLength={8}
//             onChange={textChange("referral")}
//             readOnly={getSearch?.get("referral")}
//           />
//         </div>
//         <p className="text-center d-flex align-items-center">
//           <input
//             type="checkbox"
//             name="terms"
//             className="form-check-input form-check form-check-inline"
//             value={terms}
//             onChange={(e) => setTerms(e.target.checked)}
//             id=""
//           />{" "}
//           <span>
//             By continuing you accept our standard terms and conditions and our
//             privacy policy.
//           </span>
//         </p>
//         <Buttons
//           onClick={handleSubmit}
//           loading={loading}
//           disabled={!terms}
//           title={"sign up"}
//           css="btn-primary1 text-capitalize py-3 w-100 my-4"
//         />
//         <p className="text-center">
//           Already have an account?
//           <Link
//             to={`/login`}
//             className="textColor ps-1 text-decoration-none fw-600"
//           >
//             Log in
//           </Link>{" "}
//         </p>
//         <div className="d-flex justify-content-end py-3">
//           <Link
//             to={`/activate`}
//             className="textColor text-decoration-none fw-600"
//           >
//             Activate account here
//           </Link>{" "}
//         </div>
//       </form>
//     </>
//   </DefaultAuthComponent>
//   )
// }
