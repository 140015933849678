import React from "react";
import exportTableToPDF, { exportDivTableToPDF } from "./ExportPDF";
import exportTableToExcel, { exportDivTableToExcel } from "./ExportExcel";

const ExportButtons = ({ tableId, fileName }) => {
  return (
    <div className="tw-flex tw-gap-1">
      <button
        onClick={() => exportTableToPDF(tableId, fileName)}
        className="bland tw-px-3 tw-py-3 tw-rounded-[4px]"
      >
        PDF
      </button>
      <button
        className="bland tw-px-3 tw-py-3 tw-rounded-[4px]"
        onClick={() => exportTableToExcel(tableId, fileName)}
      >
        Excel
      </button>
    </div>
  );
};

export default ExportButtons;

export const ExportButtons2 = ({ headers, data, fileName }) => {
  return (
    <div className="tw-flex tw-gap-1">
      <button
        onClick={() => exportDivTableToPDF(headers, data, fileName)}
        className="bland tw-px-3 tw-py-3 tw-rounded-[4px]"
      >
        PDF
      </button>
      <button
        className="bland tw-px-3 tw-py-3 tw-rounded-[4px]"
        onClick={() => exportDivTableToExcel(headers, data, fileName)}
      >
        Excel
      </button>
    </div>
  );
};
