export const networkData = {
  msg: "Networks retrieved successfully",
  data: [
    {
      _id: "6411b4c039fecc00ceda7854",
      name: "9MOBILE",
      image: {
        _id: "65cef8ef3f52dbfcd822681c",
        name: "1000312520",
        type: "image",
        url: "https://res.cloudinary.com/honourworld-com-ng/image/upload/v1708062957/HonourWorld/1000312520-57417.png",
        format: "png",
      },
      createdAt: "2023-03-15T12:06:24.110Z",
      updatedAt: "2024-02-16T05:55:59.841Z",
      __v: 0,
    },
    {
      _id: "6411b4d239fecc00ceda7866",
      name: "AIRTEL",
      image: {
        _id: "65c55ad32accdb82562fb4f8",
        name: "airtel-50392",
        type: "image",
        url: "https://res.cloudinary.com/honourworld-com-ng/image/upload/v1707432656/HonourWorld/airtel-50392-66850.png",
        format: "png",
      },
      createdAt: "2023-03-15T12:06:42.451Z",
      updatedAt: "2024-02-08T22:50:59.468Z",
      __v: 0,
    },
    {
      _id: "6411b4f339fecc00ceda7983",
      name: "MTN",
      image: {
        _id: "65c55a902accdb82562fb45d",
        name: "mtn-79358",
        type: "image",
        url: "https://res.cloudinary.com/honourworld-com-ng/image/upload/v1707432590/HonourWorld/mtn-79358-55586.png",
        format: "png",
      },
      createdAt: "2023-03-15T12:07:15.077Z",
      updatedAt: "2024-02-08T22:49:52.868Z",
      __v: 0,
    },
    {
      _id: "6411b4e539fecc00ceda796f",
      name: "GLO",
      image: {
        _id: "65c55aac2accdb82562fb47b",
        name: "glo-37725",
        type: "image",
        url: "https://res.cloudinary.com/honourworld-com-ng/image/upload/v1707432618/HonourWorld/glo-37725-69346.png",
        format: "png",
      },
      createdAt: "2023-03-15T12:07:01.149Z",
      updatedAt: "2024-02-08T22:50:20.935Z",
      __v: 0,
    },
  ],
};
