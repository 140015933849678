import React, { useEffect, useContext, useState } from "react";
import { Container } from "reactstrap";
import { GlobalState } from "../../Data/Context";
import { Buttons } from "../../Utils";
import { ModalComponents } from "../DefaultHeader";
import { NetworkList } from "./airtime";
import { DataDetails, MakeDataOptions } from "./data";
import LoadMore, { BottomTab } from "../LoadMore";
import moment from "moment";
import { BiTrashAlt } from "react-icons/bi";
import { BsPen } from "react-icons/bs";
import { ElectricityDetails, ElectricityOptions } from "./electricity-bills";
import { OtpComponent } from "../../Utils";
import { NumericFormat } from "react-number-format";
import { CablesDetails, CablesOptions } from "./tv-subscriptions";
import { BiCopy } from "react-icons/bi";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { ExportButtons2 } from "../../Utils/Export Functions/ExportButtons";
import { NetworkLoader } from "../../Utils/NetworkLoader";

export var monthday = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31,
];
export var dayTime = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23,
];

export var weekday = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export var frequencyArr = ["daily", "weekly", "monthly"];

export var typeArr = ["airtime", "data", "electricity", "cables"];

const MainAutoBuy = () => {
  let [isOpen, setIsOpen] = useState(false),
    [datum, setDatum] = useState(null),
    toggle = () => {
      setIsOpen(!isOpen);
      if (datum) setDatum(null);
    };

  useEffect(() => {
    if (datum) setIsOpen(true);
  }, [datum]);

  let { setStateName } = useContext(GlobalState);
  useEffect(() => {
    setStateName("auto buy");
    setIsOpen(true);
    return () => setIsOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg-white aboutScreen">
      <Container className="py-5">
        <Buttons
          title={"set options"}
          css="btn-primary1 text-capitalize py-3 px-4 px-lg-5"
          width={"w-25 w25"}
          onClick={toggle}
          style={{ borderRadius: "30px" }}
        />
        <AutoBuyHistory setDatum={setDatum} />
      </Container>
      <AutoBuyModal
        isOpen={isOpen}
        toggle={toggle}
        datum={datum}
        setDatum={setDatum}
      />
    </div>
  );
};

let AutoBuyModal = ({ isOpen, toggle, datum, setDatum }) => {
  let { returnErrors, auto_buy, manageAutoBuy, cables } =
    useContext(GlobalState);
  let init = { frequency: "daily", rate: "", type: "", properties: null },
    [state, setState] = useState(init),
    [active, setActive] = useState(0),
    [submit, setSubmit] = useState(false),
    [loading, setLoading] = useState(false),
    handleSubmit = async (e) => {
      e?.preventDefault();
      let errArr = [];
      if (!state?.type)
        errArr?.push({
          msg: `Auto buy type required`,
          param: "type",
        });
      if (!state?.frequency)
        errArr?.push({
          msg: `Auto buy frequency required`,
          param: "frequency",
        });
      if (!state?.rate)
        errArr?.push({
          msg: `Auto buy day/time required`,
          param: "rate",
        });
      if (!state?.properties)
        errArr?.push({
          msg: `Auto buy transactions properties required`,
          param: "rate",
        });
      if (errArr?.length > 0)
        return returnErrors({
          error: errArr,
        });
      let send = state;
      if (state?.type === "cables") {
        let find;
        if (
          Array?.isArray(
            cables?.cable_package?.[send?.properties?.type?.toLowerCase()]
          )
        ) {
          find = cables?.cable_package?.[
            send?.properties?.type?.toLowerCase()
          ]?.find((item) => item?.code === send?.properties?.productsCode);
          if (!find) return;
        }
        send = {
          ...send,
          properties: {
            ...send.properties,
            packagename: find?.name,
            type: send?.properties?.type?.toUpperCase(),
            amount: send?.amount || find?.price,
          },
        };
        console.log({ send, state });
      }
      setLoading(true);
      await manageAutoBuy(send, datum?._id);
      setLoading(false);
      setSubmit(true);
    };

  useEffect(() => {
    if (datum) {
      setState(datum);
    }
  }, [datum]);
  // console.log({ datum, state });

  useEffect(() => {
    if (submit && auto_buy?.isAdded) {
      setSubmit(false);
      if (datum) setDatum(null);
      setState(init);
      toggle();
      setActive(0);
    }
    if (submit && auto_buy?.isUpdated) {
      setSubmit(false);
      if (datum) setDatum(null);
      setState(init);
      toggle();
      setActive(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit, auto_buy?.isAdded]);

  return (
    <ModalComponents
      title={"Auto Buy Options Settings"}
      isOpen={isOpen}
      toggle={toggle}
    >
      <>
        {active === 3 ? (
          <>
            <p className="text-capitalize border-bottom d-flex justify-content-between printOnlyNone">
              <span>Frequency: </span>
              <span className="fontInherit Lexend">
                {state?.frequency}
              </span>{" "}
            </p>
            <p className="text-capitalize border-bottom d-flex justify-content-between printOnlyNone">
              <span>{state?.frequency === "daily" ? "Time" : "Day"}: </span>
              <span className="fontInherit Lexend">
                {state?.frequency === "daily"
                  ? Number(state?.rate) + 1 < 13
                    ? `${
                        Number(state?.rate) === 0 ? "12" : Number(state?.rate)
                      } AM`
                    : `${
                        Number(state?.rate) % 12 === 0
                          ? "12"
                          : Number(state?.rate) % 12
                      } PM`
                  : state?.rate}
              </span>{" "}
            </p>
            <p className="text-capitalize border-bottom d-flex justify-content-between printOnlyNone">
              <span>Auto buy Type: </span>
              <span className="fontInherit Lexend">{state?.type}</span>{" "}
            </p>
            {state?.type === "airtime" ? (
              <AirtimeDetails state={state?.properties} />
            ) : state?.type === "data" ? (
              <DataDetails buy={state?.properties} />
            ) : state?.type === "cables" ? (
              <CablesDetails state={state?.properties} />
            ) : state?.type === "electricity" ? (
              <ElectricityDetails state={state?.properties} />
            ) : (
              <></>
            )}
            <div className="d-flex justify-content-end">
              <Buttons
                title={"back"}
                css="btn-outline-primary1 text-capitalize"
                width={"w-auto"}
                onClick={() => {
                  setActive(2);
                }}
              />
            </div>
            <Buttons
              title={datum?._id ? "update" : "set"}
              css="btn-primary1 text-capitalize py-3 px-4 px-lg-5 mx-auto my-4"
              width={"w-50 w50"}
              onClick={handleSubmit}
              loading={loading}
            />
          </>
        ) : active === 2 ? (
          <>
            {state?.type === "airtime" ? (
              <AirtimeModalOption
                auto
                setAuto={setState}
                data={state}
                setActive={setActive}
              />
            ) : state?.type === "data" ? (
              <DataModalOptions
                auto
                setAuto={setState}
                data={state}
                setActive={setActive}
              />
            ) : state?.type === "cables" ? (
              <CablesOptions
                auto
                setAuto={setState}
                data={state}
                setActive={setActive}
              />
            ) : state?.type === "electricity" ? (
              <ElectricityOptions
                auto
                setAuto={setState}
                data={state}
                setActive={setActive}
              />
            ) : (
              <></>
            )}
            <div className="d-flex justify-content-end">
              <Buttons
                title={"back"}
                css="btn-outline-primary1 text-capitalize"
                width={"w-auto"}
                onClick={() => {
                  setActive(1);
                  setState({ ...state, properties: null });
                }}
              />
            </div>
          </>
        ) : active === 1 ? (
          <>
            <div className="btn-group w-100 py-3">
              {frequencyArr?.map((item, i) => (
                <button
                  key={i}
                  className={`btn py-3 text-capitalize fw-bold ${
                    state?.frequency === item ? "border-bottom textColor" : ""
                  } rounded-0`}
                  onClick={() => setState({ ...state, frequency: item })}
                >
                  {item}
                </button>
              ))}
            </div>
            <>
              {state?.frequency === "monthly" ? (
                <>
                  <div className="row mx-0 gy-2 gx-3 py-2 w-100">
                    {monthday?.map((item, i) => (
                      <div
                        className="col-2 p-2"
                        onClick={() => setState({ ...state, rate: item })}
                        key={i}
                      >
                        <div
                          className={`rounded border w-100 text-uppercase myCursor py-4 text-center ${
                            Number(state?.rate) === item
                              ? "list-group-item-primary"
                              : ""
                          }`}
                        >
                          {item}
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              ) : state?.frequency === "weekly" ? (
                <>
                  <div className="row mx-0 gy-2 gx-3 py-2 w-100">
                    {weekday?.map((item, i) => (
                      <div
                        className="col-6 p-2"
                        onClick={() => setState({ ...state, rate: item })}
                        key={i}
                      >
                        <div
                          className={`rounded border w-100 text-uppercase myCursor py-4 text-center ${
                            state?.rate === item
                              ? "list-group-item-primary"
                              : ""
                          }`}
                        >
                          {item}
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <>
                  <div className="row mx-0 gy-2 gx-3 py-2 w-100">
                    {dayTime?.map((item, i) => (
                      <div
                        className="col-2 p-2"
                        onClick={() => setState({ ...state, rate: item })}
                        key={i}
                      >
                        <div
                          className={`rounded border w-100 text-uppercase myCursor py-4 text-center ${
                            Number(state?.rate) === item
                              ? "list-group-item-primary"
                              : ""
                          }`}
                        >
                          {moment(item, "HH:mm:ss").format("LT")}
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </>
            <div className="mx-auto d-block w-50">
              <div className="btn-group">
                <Buttons
                  title={"prev"}
                  css="btn-primary1 text-capitalize py-3 px-4 px-lg-5 mx-auto my-4"
                  width={"w-50 w50"}
                  onClick={() => setActive(0)}
                />
                <Buttons
                  title={"next"}
                  css="btn-primary1 text-capitalize py-3 px-4 px-lg-5 mx-auto my-4"
                  width={"w-50 w50"}
                  onClick={() => {
                    if (!state?.frequency) return;
                    if (state?.frequency === "daily") {
                      if (!dayTime?.includes(state?.rate)) return;
                    } else if (!state?.rate) return;
                    setActive(2);
                  }}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="row mx-0 gy-2 gx-3 py-2 w-100">
              {typeArr?.map((item, i) => (
                <div
                  className="col-6 p-2"
                  onClick={() => setState({ ...state, type: item })}
                  key={i}
                >
                  <div
                    className={`rounded border w-100 text-uppercase myCursor py-4 text-center ${
                      state?.type === item ? "list-group-item-primary" : ""
                    }`}
                  >
                    {item}
                  </div>
                </div>
              ))}
            </div>
            <div className="mx-auto d-block w-50">
              <Buttons
                title={"next"}
                css="btn-primary1 text-capitalize py-3 px-4 px-lg-5 mx-auto my-4"
                width={"w-50 w50"}
                onClick={() => {
                  if (!state?.type) return;
                  setActive(1);
                }}
              />
            </div>
          </>
        )}
      </>
    </ModalComponents>
  );
};

export default MainAutoBuy;

export const DataModalOptions = ({ setSubmit, setAuto, data, setActive }) => {
  return (
    <>
      <MakeDataOptions
        auto
        setSubmit={setSubmit}
        setActive={setActive}
        setAuto={setAuto}
        initData={data}
      />
    </>
  );
};

export const AirtimeModalOption = ({
  auto,
  setSubmit,
  setAuto,
  data,
  setActive,
}) => {
  let {
    buyServices,
    returnErrors,
    nairaSignNeutral,
    wallet,
    usecase,
    numberWithCommas,
  } = useContext(GlobalState);

  let init = {
      phone: "",
      amount: "",
      network: "",
      pin: "",
    },
    [state, setState] = useState(init),
    [loading, setLoading] = useState(false),
    [networkLoading, setNetworkLoading] = useState(false),
    // textChange =
    // 	name =>
    // 	({ target: { value } }) => {
    // 		setState({ ...state, [name]: value });
    // 	},
    handleSubmit = async (e) => {
      e?.preventDefault();
      if (Number(state?.amount) < Number(usecase?.usecase?.airtimeMini))
        return returnErrors({
          error: [
            {
              msg: `Amount cannot be less than ${nairaSignNeutral} ${numberWithCommas(
                Number(usecase?.usecase?.airtimeMini)
              )}`,
              param: "amount",
            },
          ],
        });
      setLoading(true);
      await buyServices("airtime", state);
      setLoading(false);
      setSubmit(true);
    },
    [buyActive, setBuyActive] = useState(0);

  useEffect(() => {
    if (data?.properties) setState({ ...state, ...data?.properties });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (state?.pin && state?.pin?.length === 4)
      if (auto) {
        setAuto({ ...data, properties: state });
        setActive(3);
      } else handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.pin, auto]);

  return (
    <div className="downH2 d-flex">
      {buyActive === 2 ? (
        <TransactionPinBox
          state={state}
          setState={setState}
          handleSubmit={handleSubmit}
          loading={loading}
        />
      ) : buyActive === 1 ? (
        <>
          <div className="w-100">
            <AirtimeDetails state={state} />
            {!auto && (
              <>
                <div className="d-flex justify-content-end">
                  <Buttons
                    title={"back"}
                    css="btn-outline-primary1 text-capitalize"
                    width={"w-auto"}
                    onClick={() => {
                      setBuyActive(0);
                    }}
                  />
                </div>
              </>
            )}
            <Buttons
              title={auto || wallet?.balance?.wallet_pin ? "next" : "buy"}
              css="btn-primary1 text-capitalize py-3 px-4 px-lg-5 mx-auto"
              loading={loading}
              width={"w-50 w50"}
              onClick={
                wallet?.balance?.wallet_pin
                  ? () => {
                      setBuyActive(2);
                    }
                  : auto
                  ? () => {
                      setAuto({ ...data, properties: state });
                      setActive(3);
                    }
                  : handleSubmit
              }
              style={{ borderRadius: "30px" }}
            />
          </div>
        </>
      ) : (
        <form className="w-100" onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="telephone">Phone Number</label>
            <NumericFormat
              className="form-control py-3"
              value={state.phone}
              allowLeadingZeros={true}
              allowNegative={false}
              displayType="input"
              name="phone"
              onValueChange={(val) =>
                setState({
                  ...state,
                  phone: val?.value.replace(/[^0-9]*/g, ""),
                })
              }
              maxLength={11}
              inputMode="tel"
              renderText={(value, props) => <span {...props}>{value}</span>}
              pattern="[0-9]"
              decimalScale={0}
            />
          </div>
          {networkLoading && <NetworkLoader />}
          {/* <div className="mb-4">
            <label htmlFor="Newtwork">Network</label> */}

          <NetworkList
            state={state?.network}
            setState={(i) => {
              setState({ ...state, network: i });
            }}
            phoneNumber={state?.phone}
            setNetworkLoading={setNetworkLoading}
          />
          {/* </div> */}
          <div className="mb-4">
            <label htmlFor="value">Amount</label>
            {/* <input
							type={"number"}
							placeholder="500"
							className="form-control py-3"
							value={state?.amount}
							onChange={textChange("amount")}
							min={0}
						/> */}
            <NumericFormat
              prefix={`${nairaSignNeutral} `}
              className="form-control py-3"
              value={state.amount}
              placeholder="500"
              displayType="input"
              thousandSeparator={true}
              onValueChange={(val) =>
                setState({ ...state, amount: val?.floatValue })
              }
              min={0}
              inputMode="decimal"
              renderText={(value, props) => <span {...props}>{value}</span>}
              allowNegative={false}
            />
          </div>

          <Buttons
            title={"proceed"}
            css="btn-primary1 text-capitalize py-3 w-50 my-4 mx-auto"
            width={"w-50"}
            style={{ borderRadius: "30px" }}
            onClick={() => {
              if (Number(state?.amount) < Number(usecase?.usecase?.airtimeMini))
                return returnErrors({
                  error: [
                    {
                      msg: `Amount cannot be less than ${nairaSignNeutral} ${numberWithCommas(
                        Number(usecase?.usecase?.airtimeMini)
                      )}`,
                      param: "amount",
                    },
                  ],
                });
              if (Number(state?.amount) > Number(usecase?.usecase?.airtimeMax))
                return returnErrors({
                  error: [
                    {
                      msg: `Amount cannot be more than ${nairaSignNeutral} ${numberWithCommas(
                        Number(usecase?.usecase?.airtimeMax)
                      )}`,
                      param: "amount",
                    },
                  ],
                });
              if (!state?.network || !state?.phone)
                return returnErrors({
                  error: [
                    {
                      msg: `Please select a network and input telephone number`,
                      param: "telephone",
                    },
                  ],
                });
              if (!state?.phone?.startsWith("0"))
                return returnErrors({
                  error: [
                    {
                      msg: `${state?.phone} must start with a 0 digit`,
                      param: "telephone",
                    },
                  ],
                });
              if (state?.phone?.length !== 11)
                return returnErrors({
                  error: [
                    {
                      msg: `Phone number should be 11 digits`,
                      param: "telephone",
                    },
                  ],
                });
              setBuyActive(1);
            }}
          />
        </form>
      )}
    </div>
  );
};

export const TransactionPinBox = ({
  state,
  loading,
  setState,
  handleSubmit,
  title,
}) => {
  return (
    <div className="d-flex flex-column w-100">
      <small className="mb-4 d-block text-center">
        Enter your transaction pin
      </small>
      <div className="d-flex justify-content-center my-5 mx-auto">
        <OtpComponent
          stateData={state?.pin}
          textChange={(data) => {
            setState({ ...state, pin: data });
          }}
          css="borderColor"
          loading={loading}
          numInputs={4}
          isInputSecure={true}
        />
      </div>
      <div className="w-100">
        <Buttons
          title={title || "buy"}
          css="btn-primary1 text-capitalize py-3 px-4 px-lg-5 mx-auto"
          loading={loading}
          width={"w-50 w50"}
          onClick={() => {
            if (!state?.pin || state?.pin?.length !== 4) return;
            handleSubmit();
          }}
          style={{ borderRadius: "30px" }}
        />
      </div>
    </div>
  );
};

const AirtimeDetails = ({ state }) => {
  let { numberWithCommas, nairaSign, settings, auth } = useContext(GlobalState);
  let [stateData, setStateData] = useState(null);
  useEffect(() => {
    setStateData(settings?.settings);
  }, [settings?.settings]);

  const getCommission = (type, amount) => {
    let com;
    switch (type) {
      case "MTN": {
        com =
          auth?.user?.privilege === "agent"
            ? stateData?.mtnAgentCommission || stateData?.mtnCommission
            : auth?.user?.privilege === "reseller"
            ? stateData?.mtnResellerCommission || stateData?.mtnCommission
            : stateData?.mtnCommission;

        return (com / 100) * amount;
      }
      case "GLO": {
        com =
          auth?.user?.privilege === "reseller"
            ? stateData?.gloResellerCommission || stateData?.gloCommission
            : auth?.user?.privilege === "agent"
            ? stateData?.gloAgentCommission || stateData?.gloCommission
            : stateData?.gloCommission;

        return (com / 100) * amount;
      }
      case "AIRTEL": {
        com =
          auth?.user?.privilege === "reseller"
            ? stateData?.airtelResellerCommission || stateData?.airtelCommission
            : auth?.user?.privilege === "agent"
            ? stateData?.airtelAgentCommission || stateData?.airtelCommission
            : stateData?.airtelCommission;

        return (com / 100) * amount;
      }
      case "9MOBILE": {
        com =
          auth?.user?.privilege === "reseller"
            ? stateData?.mobile9ResellerCommission ||
              stateData?.mobile9Commission
            : auth?.user?.privilege === "agent"
            ? stateData?.mobile9AgentCommission || stateData?.mobile9Commission
            : stateData?.mobile9Commission;

        return (com / 100) * amount;
      }
      default:
        return null;
    }
  };
  return (
    <>
      <p className="text-capitalize border-bottom d-flex justify-content-between printOnlyNone">
        <span>Network: </span>
        <span className="fontInherit Lexend">{state?.network}</span>{" "}
      </p>
      <p className="text-capitalize border-bottom d-flex justify-content-between printOnlyNone">
        <span>Amount: </span>
        <span className="fontInherit Lexend">
          {nairaSign} {numberWithCommas(Number(state?.amount).toFixed(2))}
        </span>{" "}
      </p>
      <p className="text-capitalize border-bottom d-flex justify-content-between printOnlyNone">
        <span>Commission: </span>
        <span className="fontInherit Lexend">
          {nairaSign}{" "}
          {numberWithCommas(
            Number(getCommission(state?.network, state?.amount)).toFixed(2)
          )}
        </span>{" "}
      </p>
      <p className="text-capitalize border-bottom d-flex justify-content-between printOnlyNone">
        <span>Recipient number: </span>
        <span
          className="fontInherit Lexend force-d-flex myCursor"
          onClick={
            state?.phone
              ? () => {
                  navigator.clipboard.writeText(state?.phone).then(
                    () => {
                      toast.info("Copied", { autoClose: 2000 });
                    },
                    (err) => {
                      toast.warn(`Could not copy: ${err}`, {
                        autoClose: 2000,
                      });
                    }
                  );
                }
              : null
          }
        >
          {state?.phone}
          <BiCopy />
        </span>{" "}
      </p>
    </>
  );
};

const AutoBuyHistory = ({ setDatum }) => {
  let { auto_buy, getAutoBuy, numberWithCommas, manageAutoBuy } =
    useContext(GlobalState);

  let [data, setData] = useState(null),
    [thisData, setThisData] = useState(null),
    [load2, setLoad2] = useState(null),
    [isDelete, setIsDelete] = useState(null);
  const params = useParams();

  useEffect(() => {
    setData(auto_buy?.data);
  }, [auto_buy?.data]);

  useEffect(() => {
    getAutoBuy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let [loading, setLoading] = useState(false);
  let handleLoadMore = async () => {
    setLoading(true);

    await getAutoBuy({
      page: Number(auto_buy?.paginate?.nextPage),
      // limit: Number(auto_buy?.paginate?.nextPage * auto_buy?.paginate?.limit),
    });
    setLoading(false);
  };

  if (!data) return;
  // console.log({ data });

  const headers = [
    { title: "ID", field: "id" },
    { title: "Frequency", field: "frequency" },
    { title: "Type", field: "type" },
    { title: "Created On", field: "created_on" },
    { title: "Previous Invocation", field: "previous_invocation" },
    { title: "Next Invocation", field: "next_invocation" },
    { title: "No of Invocation", field: "no_of_invocation" },
    { title: "Status", field: "status" },
  ];

  const tableData = data?.map((item, index) => ({
    id: item?.item_id ? `${item?.item_id}` : "",
    frequency: item?.frequency ? `${item?.frequency}` : "",
    type: item?.type ? `${item?.type}` : "",
    created_on: moment(item?.createdAt).format("DD/MM/YYYY hh:mm A"),
    previous_invocation: item?.prevInvocation
      ? moment(item?.prevInvocation).format("ddd Do, MMM YYYY HH:mmA")
      : "",
    next_invocation: item?.nextInvocation
      ? moment(item?.nextInvocation).format("ddd Do, MMM YYYY HH:mmA")
      : "",
    no_of_invocation: `${
      item?.numberOfInvocation ? numberWithCommas(item?.numberOfInvocation) : 0
    } ${
      item?.numberOfSuccessfulInvocation
        ? item?.numberOfSuccessfulInvocation > 0
          ? `(${numberWithCommas(item?.numberOfSuccessfulInvocation)})`
          : ""
        : ""
    }`,
  }));

  return (
    <div className="pb-5 my-5">
      <div className="tw-flex tw-justify-between tw-items-center tw-pb-2">
        <div className="tw-flex-1"></div>
        <ExportButtons2
          headers={headers}
          data={tableData}
          fileName={`${params?.page} ${params?.id ? `(${params?.id})` : ""}`}
        />
      </div>
      <div className="bland row mx-0 p-3 text-capitalize">
        <div className="col textTrunc fontReduce d-none d-md-flex fw-bold">
          ID
        </div>
        <div className="col textTrunc fontReduce fw-bold Lexend">Frequency</div>
        <div className="col textTrunc fontReduce fw-bold Lexend">Type</div>
        <div className="col textTrunc fontReduce fw-bold Lexend d-none d-md-flex">
          created on
        </div>
        {/* <div className="col textTrunc fontReduce fw-bold Lexend">Day/Time</div> */}
        <div className="col textTrunc fontReduce fw-bold Lexend d-none d-md-flex">
          prev Invocation
        </div>
        <div className="col textTrunc fontReduce fw-bold Lexend d-none d-md-flex">
          Next Invocation
        </div>
        <div className="col textTrunc fontReduce fw-bold Lexend d-none d-md-flex">
          No of Invocation
        </div>
        <div className="col textTrunc fontReduce fw-bold Lexend">status</div>
      </div>
      <div className="bg-white row mx-0">
        {data?.length === 0 ? (
          <></>
        ) : (
          data?.map((item, index) => (
            <div key={index} className="row mx-0 py-3 border-bottom myCursor">
              <div
                onClick={() => setThisData(item)}
                className="col textTrunc fontReduce2 my-auto d-none d-md-flex"
              >
                {item?.item_id}
              </div>
              <div
                onClick={() => setThisData(item)}
                className="col textTrunc fontReduce2 my-auto text-capitalize"
              >
                {item?.frequency}
              </div>
              <div
                onClick={() => setThisData(item)}
                className="col textTrunc fontReduce2 my-auto text-capitalize"
              >
                {item?.type}
              </div>
              <div
                onClick={() => setThisData(item)}
                className="col textTrunc fontReduce2 my-auto d-none d-md-flex"
              >
                {moment(item?.createdAt).format("DD/MM/YYYY HH:mm A")}
              </div>
              {/* <div
								onClick={() => setThisData(item)}
								className="col textTrunc fontReduce2 my-auto">
								{item?.rate}
							</div> */}
              <div
                onClick={() => setThisData(item)}
                className="col textTrunc fontReduce2 my-auto d-none d-md-flex"
              >
                {item?.prevInvocation
                  ? moment(item?.prevInvocation).format(
                      "ddd Do, MMM YYYY HH:mmA"
                    )
                  : null}
              </div>
              <div
                onClick={() => setThisData(item)}
                className="col textTrunc fontReduce2 my-auto d-none d-md-flex"
              >
                {item?.nextInvocation
                  ? moment(item?.nextInvocation).format(
                      "ddd Do, MMM YYYY HH:mmA"
                    )
                  : null}
              </div>
              <div
                onClick={() => setThisData(item)}
                className="col textTrunc fontReduce2 my-auto d-none d-md-flex"
              >
                {item?.numberOfInvocation
                  ? numberWithCommas(item?.numberOfInvocation)
                  : 0}{" "}
                {item?.numberOfSuccessfulInvocation
                  ? item?.numberOfSuccessfulInvocation > 0
                    ? `(${numberWithCommas(
                        item?.numberOfSuccessfulInvocation
                      )})`
                    : ""
                  : ""}
              </div>
              <div className="col textTrunc fontReduce2 my-auto">
                <div className="btn-group">
                  <button
                    title="Edit Auto buy"
                    onClick={() => setDatum(item)}
                    className="btn btn-success2 text-capitalize p-1 p-md-2 w-100 fontReduce2"
                  >
                    <BsPen />
                  </button>{" "}
                  <button
                    title="Delete Auto buy"
                    onClick={() => setIsDelete(item)}
                    className="btn btn-danger2 text-capitalize p-1 p-md-2 w-100 fontReduce2"
                  >
                    <BiTrashAlt />
                  </button>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      <AutoModalDetails thisData={thisData} setThisData={setThisData} />

      <BottomTab state={data} paginate={auto_buy?.paginate} />
      <LoadMore
        next={auto_buy?.paginate?.next}
        handleLoadMore={handleLoadMore}
        loading={loading}
      />
      <ModalComponents
        title={"Delete Auto Buy"}
        isOpen={isDelete ? true : false}
        back={() => {
          setIsDelete(false);
        }}
      >
        <form
          onSubmit={async () => {
            setLoad2({ isLoad: true, loadData: isDelete?._id });
            await manageAutoBuy(isDelete, isDelete?._id, "delete");
            setLoad2({ isLoad: false, loadData: "" });
            setIsDelete(null);
          }}
        >
          <p className="text-center">Do you want to delete this Auto Buy?</p>
          <div className="tw-flex tw-justify-center">
            <div className="btn-group mx-auto w-50 w50">
              <Buttons
                loading={load2?.isLoad && load2?.loadData === isDelete?._id}
                onClick={async () => {
                  setLoad2({ isLoad: true, loadData: isDelete?._id });
                  await manageAutoBuy(isDelete, isDelete?._id, "delete");
                  setLoad2({ isLoad: false, loadData: "" });
                  setIsDelete(null);
                }}
                width="w-50"
                css="btn-success-2 btn-success2 text-capitalize py-3 w-50"
                title={"yes"}
              />
              <Buttons
                onClick={() => setIsDelete(null)}
                width="w-50"
                css="btn-danger-2 btn-danger2 text-capitalize py-3 w-50"
                title={"no"}
                type={"no"}
              />
            </div>
          </div>
        </form>
      </ModalComponents>
    </div>
  );
};

export const AutoModalDetails = ({ thisData, setThisData }) => {
  let {
    auth,
    numberWithCommas,
    // nairaSign
  } = useContext(GlobalState);
  return (
    <>
      <ModalComponents
        isOpen={thisData ? true : false}
        toggle={() => setThisData(false)}
        title="Auto buy details"
      >
        <div className="downH2 d-flex flex-column">
          <p className="text-capitalize border-bottom d-flex justify-content-between printOnlyNone">
            <span>Id: </span>
            <span
              className="fontInherit Lexend force-d-flex myCursor"
              onClick={
                thisData?.item_id
                  ? () => {
                      navigator.clipboard.writeText(thisData?.item_id).then(
                        () => {
                          toast.info("Copied", { autoClose: 2000 });
                        },
                        (err) => {
                          toast.warn(`Could not copy: ${err}`, {
                            autoClose: 2000,
                          });
                        }
                      );
                    }
                  : null
              }
            >
              {thisData?.item_id}
              <BiCopy />
            </span>{" "}
          </p>
          {thisData?.channel && thisData?.channel !== "auth" && (
            <p className="text-capitalize border-bottom d-flex justify-content-between">
              <span>Channel: </span>
              <span className="fontInherit Lexend">
                {thisData?.channel}
              </span>{" "}
            </p>
          )}
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>type: </span>
            <span className={`fontInherit Lexend`}>{thisData?.type}</span>{" "}
          </p>
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>frequency: </span>
            <span className={`fontInherit Lexend`}>
              {thisData?.frequency}
            </span>{" "}
          </p>
          {/* <p className="text-capitalize border-bottom d-flex justify-content-between">
						<span>day/time: </span>
						<span className={`fontInherit Lexend`}>{thisData?.rate}</span>{" "}
					</p> */}
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>date&time: </span>
            <span className="fontInherit Lexend">
              {moment(thisData?.createdAt).format("ddd Do, MMM YYYY HH:mmA")}
            </span>{" "}
          </p>
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>next invocation: </span>
            <span className="fontInherit Lexend">
              {!thisData?.nextInvocation
                ? null
                : moment(thisData?.nextInvocation).format(
                    "ddd Do, MMM YYYY HH:mmA"
                  )}
            </span>{" "}
          </p>
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>previous invocation: </span>
            <span className="fontInherit Lexend">
              {!thisData?.prevInvocation
                ? null
                : moment(thisData?.prevInvocation).format(
                    "ddd Do, MMM YYYY HH:mmA"
                  )}
            </span>{" "}
          </p>
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>number of invocation: </span>
            <span className="fontInherit Lexend">
              {thisData?.numberOfInvocation
                ? numberWithCommas(thisData?.numberOfInvocation)
                : 0}
            </span>{" "}
          </p>
          {auth?.user?.isAdmin && (
            <p className="border-bottom d-flex justify-content-between">
              <span className="text-capitalize">User: </span>
              <span>
                <span className="fontInherit Lexend d-block text-capitalize">
                  {thisData?.user?.lastName} {thisData?.user?.firstName}
                </span>{" "}
                <span className="fontInherit Lexend d-block">
                  {thisData?.user?.telephone}
                </span>{" "}
                <span className="fontInherit Lexend d-block">
                  {thisData?.user?.email}
                </span>{" "}
              </span>
            </p>
          )}
          {thisData?.type === "airtime" ? (
            <AirtimeDetails state={thisData?.properties} />
          ) : thisData?.type === "data" ? (
            <DataDetails buy={thisData?.properties} />
          ) : thisData?.type === "electricity" ? (
            <ElectricityDetails buy={thisData?.properties} />
          ) : thisData?.type === "cables" ? (
            <CablesDetails state={thisData?.properties} />
          ) : (
            <></>
          )}
          <div className="ms-auto d-flex align-items-center">
            <button
              className="btn btn-danger-2 btn-danger2 d-block ms-auto me-2"
              onClick={() => setThisData(false)}
            >
              Close
            </button>
          </div>
        </div>
      </ModalComponents>
    </>
  );
};
